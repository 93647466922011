import React from "react";

import log from "../images/aboutthem.jpeg";
import { FaLocationDot, FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";

const About = () => {
  // const aboutCards = [
  //   {
  //     title: "Photography & Videography",

  //     Image: about_1,
  //     description:
  //       "Capture life's moments in stunning detail with our exceptional photography and videography services. Preserve memories with artistry and precision. Let us bring your vision to life.",
  //   },

  //   {
  //     title: "Graphics Design",
  //     Image: about_2,
  //     description:
  //       "Unleash creativity and elevate your brand with captivating graphic design services. From logos to visuals, we bring your ideas to life. Enhance your website's aesthetic appeal now.",
  //   },

  //   {
  //     title: "Mentorship & life coaching",

  //     Image: about_3,
  //     description:
  //       "Unlock your full potential with transformative mentorship and life coaching services. Gain guidance, clarity, and support to achieve personal and professional growth. Empower yourself to thrive and succeed.",
  //   },

  //   {
  //     title: "Public address system",

  //     Image: about_4,
  //     description:
  //       "We provide clear sound, powerful speakers, and versatile microphones ensure effective communication in any venue or event. Enhance public gatherings with ease.",
  //   },

  //   {
  //     title: "Events management",

  //     Image: about_5,
  //     description:
  //       "Effortlessly organize unforgettable events with our expertly curated event management service. Simplify planning, coordination, and execution for seamless experiences. Enhance your website today.",
  //   },
  //   {
  //     title: "Social media marketing & Advertisement",

  //     Image: about_6,
  //     description:
  //       "Boost your online presence and reach new heights with our dynamic social media marketing services. Engage, connect, and grow your audience effectively. Ignite your brand's success today.",
  //   },
  // ];
  return (
    <div className="hero-section ">
      <section className="justify-content-center align-items-center d-flex">
        <img
          style={{
            objectFit: "cover",
            minHeight: "400px",
            maxHeight: "800px",
          }}
          src={log}
          className="w-100 shodows"
          alt="rectangle"
        />
        <div className="position-absolute d-flex justify-content-center align-items-center">
          <button className="contact_button py-2 px-4 text-light">
            ABOUT US
          </button>
        </div>
      </section>

      <div className="pt-5 container">
        <p className="pt-3">
          <strong>Basileia Media & Promotions</strong> Basileia Media &
          Promotions is a dynamic agency specializing in digital marketing,
          events management, content production, and talent promotion. We are
          committed to delivering innovative solutions and exceptional results
          for our clients.
        </p>
        <p>
          We operate across various platforms, including traditional print and
          broadcast media, digital platforms, social media, streaming services,
          and more. This allows us to reach a wide range of audiences and adapt
          to changing consumer preferences. We have embraced online platforms
          and streaming services to distribute our content and engage with
          audiences directly. This shift has disrupted traditional business
          models, challenged the sustainability of print newspapers and
          broadcast television. This makes us informed about content creation,
          advertising strategies, and audience engagement.
        </p>
        <p>
          We offers consultancy services to help businesses and organizations
          navigate the media landscape. They provide strategic advice on media
          planning, audience targeting, content strategy ,and brand positioning.
          The company also conduct market research, audience surveys, and data
          analysis to inform business decisions. By analyzing media data,
          organizations can identify emerging trends and topics of interest.
          This information helps shape content strategies, product development,
          and marketing campaigns to align with current market demands.
        </p>
      </div>

      <div className="contact_bg py-5">
        <div className="container py-5">
          <div id="contact" className="row align-items-stretch justify-content-center text-center w-100">
            <div className="col-lg-4 col-md-4 col-sm-10">
              <div className="card h-100 py-4 d-flex flex-column justify-content-between">
                <div>
                  <FaLocationDot className="contactIcons mb-3" />
                </div>
                <div>
                  <p>
                    Naluvule Hoima Road near Prompet Station next to Youth
                    Platform Africa, Wakiso District.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-10">
              <div className="card h-100 py-4 d-flex flex-column justify-content-between">
                <div>
                  <FaPhone className="contactIcons mb-3" />
                </div>
                <div><p>Contact Us</p></div>
                <p>+256 777 667 080 | +256 757 217 681</p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-10">
              <div className="card h-100 py-4 d-flex flex-column justify-content-between">
                <div>
                  <MdEmail className="contactIcons mb-3" />
                </div>
                <div><p>Send us an e-mail</p></div>
                <div>
                  <p>zoemediainsights@gmail.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="w-100 mt-5">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.727183015334!2d32.50697301057881!3d0.3872665996073079!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dafc0830d472b%3A0xa365fefda28e41ae!2sBASILEIA%20MEDIA%20%26%20PROMOTIONS!5e0!3m2!1sen!2sug!4v1711005601932!5m2!1sen!2sug"
              width="100%"
              height="500"
              title="map"
              style={{ border: 0 }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
