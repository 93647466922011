import React from "react";

import Footer from "./pages/Footer";
import { Outlet } from "react-router-dom";
import Navbar from "./pages/Navbar";
const OutletMain = () => {
  return (
    <div>
      <Navbar />
      <Outlet />
      <Footer />
    </div>
  );
};

export default OutletMain;
