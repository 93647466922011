import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { IoClose } from "react-icons/io5";
import { RiMenuFoldFill } from "react-icons/ri";
import Logo from "../images/basileia_logo-removebg-preview.png";

const Nav = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar container">
      
        <div className="d-flex justify-content-between align-items-center">
          <h5 id="logo">
            <Link to="/">
              <img src={Logo} alt="" className="w-50" />
            </Link>
          </h5>
     
        
            <button className="navbar-toggle" onClick={toggleMenu}>
              {isMenuOpen ? <IoClose /> : <RiMenuFoldFill />}
            </button>
          </div>
          <div>
            <ul className={`navbar-menu ${isMenuOpen ? "open" : ""}`}>
              <li className="navbar-item">
                <Link to="/about" className="nav-link">
                  ABOUT US
                </Link>
              </li>
              <li className="navbar-item">
                <Link to="/services" className="nav-link">
                  SERVICES
                </Link>
              </li>
              <li className="navbar-item">
                <Link to="/events" className="nav-link">
                  EVENTS
                </Link>
              </li>
            </ul>
         
      </div>
    </nav>
  );
};

export default Nav;
