import React from "react";
import { CiFacebook, CiYoutube } from "react-icons/ci";
import { FaTiktok } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FaLocationDot, FaEnvelope, FaPhone } from "react-icons/fa6";

const Footer = () => {
  return (
    <div>
      <div className="  py-5">
        <div className=" container  py-3">
          <div className="about_us row justify-content-between ">
            <div className="info col-md-4 col-sm-6 ">
              <h3>
                <strong>Elevate Your Brand Today</strong>
              </h3>
              <p>Elevate Your Experience with Our Services</p>
            </div>
            <div className="digital col-md-4 col-sm-6">
              <p>
                Our services are:
                <br />
                Excellent, Creativite & Innovative, Exeptional, Expertised &
                Empowerment.
              </p>
            </div>
           
          </div>
        </div>
      </div>
      <div className="topfooter">
        <div className="text-white footer container">
          <div className="row">
            <div className="col-lg-3 col-md-5 d-none d-md-block  custom-height">
              <h5>Basileia Media & Promotions</h5>
              <p className="w-75">
                Serves as a bridge between the ever-expanding media universe,
                individuals, businesses, and institutions seeking to make sense
                of it all.
              </p>
            </div>
            <div className="col-lg-3 d-none d-md-block col-md-5">
              <h5>EVENTS</h5>
              <p className="custom-height">Weddings</p>
              <p className="custom-height">Introductions</p>
              <p className="custom-height">Parties</p>
              <p className="custom-height">Concerts</p>
            </div>

            <div className="col-lg-3 d-none d-md-block col-md-5">
              <h5>QUICK LINKS</h5>
              <Link to="/about" className="link-unstyled">
                <p className=" custom-height text-light">About-us</p>
              </Link>
              <Link to="/services " className="link-unstyled">
                <p className=" custom-height text-light">Services</p>
              </Link>
              <Link to="/events " className="link-unstyled">
                <p className=" custom-height text-light"> Events</p>
              </Link>
            </div>

            <div className="col-lg-3 col-md-5">
              <h5>FIND US</h5>

              <div className="d-flex align-items-center gap-2">
                <div>
                  <FaEnvelope />
                </div>

                <div className="custom-height">zoemediainsights@gmail.com</div>
              </div>
              <div className="d-flex my-2 align-items-center gap-2">
                <div>
                  <FaPhone />
                </div>
                <div className="custom-height">
                  +256 777 667 080 / +256 757 217 681
                </div>
              </div>
              <div className="d-flex  gap-2">
                <div>
                  <FaLocationDot />
                </div>
                <div className="custom-height">
                  Naluvule Hoima Road near Prompet Station next to Youth
                  Platform Africa, Wakiso District.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className=" bottomfooter">
        <div className=" d-flex py-3">
          <div className="container  d-flex justify-content-between">
            <div className="text-ceter align-items-center text-light">
              <p>Our Social media handles ---></p>
            </div>
            <div className="d-flex justify-content-between">
              <div className="footer-icons">
                <a href="https://www.facebook.com/photo.php?fbid=122096956664022437&set=a.122096956688022437&type=3">
                  <CiFacebook size={30} className="text-light" />
                </a>
              </div>
              <div className="footer-icons mx-3">
                <a href="https://youtu.be/hdNcE6rVqzs?si=2YZ-gNxMt0tof2Lq">
                  <CiYoutube size={30} className="text-light" />
                </a>
              </div>

              <div className="footer-icons">
                <a href="https://www.tiktok.com/@basileiamediapromotions?_t=8knhPV4sQk9&_r=1">
                  <FaTiktok size={25} className="text-light" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
