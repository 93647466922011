import React from "react";
import event from "../images/Rectangle 122.jpg";

const Events = () => {
  return (
    <div className="hero-section">
      <section className="justify-content-center align-items-center d-flex">
        <img
          style={{
            objectFit: "cover",
            minHeight: "400px",
            maxHeight: "700px",
          }}
          src={event}
          className="w-100 shodows"
          alt="..."
        />
        <div className="position-absolute d-flex justify-content-center align-items-center">
          <button className="contact_button py-2 px-4">EVENTS</button>
        </div>
      </section>
      <div className="p-5 container">
        <p>
          Events play a significant role in human society, serving as platforms
          for social interaction, cultural exchange, entertainment, education,
          and more. From small community gatherings to large-scale international
          conferences, events bring people together, foster connections, and
          create memorable experiences. This essay will delve into the
          significance of events and explore their various forms, impacts, and
          purposes.
        </p>
        <p>
          Events are diverse in nature and can be categorized into different
          types based on their objectives and themes. Cultural festivals
          celebrate the traditions and customs of a particular community or
          region, showcasing music, dance, art, and cuisine. These events not
          only promote cultural diversity but also provide opportunities for
          individuals to learn about different societies and broaden their
          horizons.
        </p>
        <p>These are some of the Events we manage; </p>

        <table className=" table table-bordered">
          <tbody className="w-50 ">
            <tr>
              <td>Weddings</td>
              <td>Seminars</td>
            </tr>
            <tr>
              <td>Introductions</td>
              <td>Confrences</td>
            </tr>
            <tr>
              <td>Graduations</td>
              <td>Sports & events</td>
            </tr>
            <tr>
              <td>Birthdays</td>
              <td>Talent shows</td>
            </tr>
            <tr>
              <td>Crusades</td>
              <td>Retirements</td>
            </tr>
            <tr>
              <td>Outings</td>
              <td>Trade shows</td>
            </tr>
            <tr>
              <td>Cultural Events</td>
              <td>Film festivals</td>
            </tr>

            <tr>
              <td>Road shows</td>
              <td>Workshops</td>
            </tr>
            <tr>
              <td>Retreats</td>
              <td>Presentations</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Events;
