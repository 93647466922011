import React from "react";

import { Container } from "react-bootstrap";
import talent from "../images/talentpromotion.jpg";
import graphics from "../images/pexels-steve-johnson-1096664.jpg";
import social from "../images/pexels-magnus-mueller-2818118.jpg";
import events from "../images/Rectangle 106.jpg";
import service from "../images/Rectangle 111.jpg";
const Services = () => {
  return (
    <div className="hero-section">
      <section className="justify-content-center align-items-center d-flex shadows">
        <img
          style={{
            objectFit: "cover",
            minHeight: "400px",
            maxHeight: "700px",
          }}
          src={service}
          className="w-100 shodows"
          alt="..."
        />
        <div className="position-absolute d-flex justify-content-center align-items-center">
          <button className="contact_button py-2 px-4">SERVICES</button>
        </div>
      </section>

      <Container>
        <div className="events py-5">
          <div className="row my-5 align-items-center d-flex justify-content-center">
            <div className="col-lg-6 col-md-10 col-sm-12">
              <img src={social} alt="" className="w-100 serviceImg" />
            </div>
            <div className="col-lg-6 col-md-10 col-sm-12">
              <h4>Digital Marketing</h4>
              <p>
                At Basileia Media & Promotions, we specialize in tailored
                digital marketing solutions to enhance businesses' online
                presence and drive growth. By understanding clients' objectives
                and industry landscape, we devise resonant strategies leveraging
                innovative techniques. From SEO refinement to compelling social
                media campaigns, we deliver measurable outcomes, propelling
                businesses forward.
              </p>
              <p>
                We prioritize engagement and conversion, ensuring maximum
                impact. Through meticulous audience segmentation and
                personalized messaging, we craft immersive brand experiences
                across digital touchpoints. From captivating content to
                strategic influencer partnerships, we captivate target audiences
                effectively, fostering meaningful connections. We cultivate
                digital ecosystems for sustained success in today's
                ever-evolving landscape.
              </p>
            </div>
          </div>

          <div className="row my-5 align-items-center d-flex justify-content-center">
            <div className="col-lg-6 col-md-10 col-sm-12">
              <img src={events} alt="" className="w-100 serviceImg" />
            </div>
            <div className="col-lg-6 col-md-10 col-sm-12">
              <h4>Events Management</h4>
              <p>
                Our dedication to memorable events is limitless. With a diverse
                portfolio spanning business summits, ceremonies, concerts, and
                more, we curate experiences leaving lasting impressions. From
                concept to execution, our team ensures meticulous planning and
                flawless execution. Whether corporate or community events, our
                expertise delivers seamless experiences, exceeding expectations.
                With innovation and passion, we transform visions into
                unforgettable affairs.
              </p>
              <p>
                At our core, we commit to excellence, surpassing client goals.
                Through collaborative planning and strategic execution, we
                navigate event logistics seamlessly. Beyond logistics, we
                prioritize attendee engagement, curating immersive environments
                where connections flourish. With a proven track record, our
                Company is the choice for exceptional event management. Let us
                elevate your event, leaving a lasting impression.
              </p>
            </div>
          </div>

          <div className="row my-5 align-items-center d-flex justify-content-center">
            <div className="col-lg-6 col-md-10 col-sm-12">
              <img src={graphics} alt="" className="w-100 serviceImg" />
            </div>
            <div className="col-lg-6 col-md-10 col-sm-12">
              <h4>Content Production</h4>
              <p>
                We offer a comprehensive suite of media content production
                services, including photography, videography, and graphic
                design. Our dedicated team ensures exceptional results,
                leveraging the latest tools and techniques to create
                high-quality content that resonates with your audience. From
                enhancing online presence to launching new products, our
                expertise guarantees lasting impressions.
              </p>
              <p>
                Our holistic approach ensures brand cohesion and enhances recall
                across all channels. We collaborate closely with clients to
                tailor our services to their specific needs, crafting visually
                stunning narratives and engaging social media content. With
                creativity, technical proficiency, and strategic insight, we
                drive meaningful connections and business growth in today's
                digital landscape.
              </p>
            </div>
          </div>

          <div className="row my-5 align-items-center d-flex justify-content-center">
            <div className="col-lg-6 col-md-10 col-sm-12">
              <img src={talent} alt="" className="w-100 serviceImg" />
            </div>
            <div className="col-lg-6 col-md-10 col-sm-12">
              <h4>Talent Promotion</h4>
              <p>
                With an unwavering commitment to fostering and uplifting
                gospel-based talents and music, our primary goal is to shine a
                spotlight on the remarkable individuals within this vibrant
                community. Drawing from a deep understanding of the gospel genre
                and its profound significance, we excel in providing unmatched
                talent promotion services. Our tailored approaches acknowledge
                the transformative power of gospel music as a catalyst for
                inspiration and solidarity, reaffirming our dedication to artist
                empowerment. Through our efforts to expand their audience reach,
                we strive to cultivate lasting impact, guiding artists on a path
                towards wider recognition and resonance.
              </p>
              <p>
                Our mission is centered on nurturing and uplifting gospel-based
                music talents, guided by a deep understanding of the genre. We
                provide personalized talent promotion services that harness the
                inspirational essence of gospel music, aiming to amplify
                artists' voices and expand their influence. Through
                collaborative efforts, we champion artists towards greater
                recognition, enriching cultural landscapes with the
                transformative power of gospel music.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Services;
