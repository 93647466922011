import React from "react";
import { Carousel } from "react-bootstrap";
import slide_1 from "../images/maxresdefault.jpg";
import slide_3 from "../images/camera guy.jpg";
import { Link } from "react-router-dom";

const images = [
  {
    url: slide_1,
    caption: "EVENTS & WEDDINGS",
  },
  {
    url: slide_1,
    caption: "SOCIAL MEDIA MARKETING & ADVERTISEMENT",
  },
  {
    url: slide_3,
    caption: "EXPERTS IN PHOTOGRAPHY & VIDEOGRAPHY",
  },
];

const cards = [
 
  {
    title: "Digital Marketing",
    description:
      "We offer customized solutions and services designed to boost businesses' online presence and growth. Our platform utilizes diverse digital channels and strategies to amplify brand visibility and captivate target audiences effectively.",
  },
  {
    title: "Events Management",
    description:
      "We organize and manage a wide array of events, such as business summits, ceremonies, concerts, etc. Our expertise in Event Management ensures seamless execution and unforgettable experiences for all our clients.",
  },
  {
    title: "Content Production",
    description:
      "We also excel in media content production, providing exceptional photography, videography, and graphic design services. Our expertise guarantees high-quality content that captivates and engages audiences effectively.",
  },
  
  {
    title: "Talent Promotion",
    description:
      "We specialize in promoting gospel-based talents and music, providing expert talent promotion services tailored to uplift and showcase these unique talents.",
  },
];

export const Home = () => {
  return (
    <div className="hero-section">
      <Carousel className="shodows">
        {images.map((image, index) => (
          <Carousel.Item key={index} className="carousel-slide">
            <div className="justify-content-center align-items-center d-flex">
              <img
                className="d-block w-100"
                src={image.url}
                alt={`Slide ${index + 1}`}
                style={{
                  objectFit: "cover",
                  minHeight: "400px",
                  maxHeight: "800px",
                }}
              />
              <Carousel.Caption>
                <p className="home-btn text-light">{image.caption}</p>
              </Carousel.Caption>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
      {/* cards section */}
      <div id="service" className="card-section">
        <div className="d-flex justify-content-center text-center ">
          <h1>What We Do</h1>
        </div>
        <div className="card-container">
          {cards.map((card, index) => (
            <div key={index} className="flip-card">
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <h5 className="align-items-center justify-content-center d-flex px-4 font-weight-bold text-center">
                    {card.title}
                  </h5>
                </div>
                <div className="flip-card-back">
                  <p className="align-items-center justify-content-center  text-white px-3 ">
                    {card.description}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="align-items-center text-center d-flex justify-content-center py-5">
        <div>
          <div></div>
          <h2>Connect With Us</h2>
          <p>Get in touch with us for quality and improved services</p>

          <div className="align-items-center d-flex justify-content-center">
            <button className="homeC_button">
              <Link
                style={{ textDecorationLine: "none", color: "#FFF" }}
                to="/about#contact"
              >
                Contact
              </Link>
            </button>
          </div>
        </div>
      </div>
      

    </div>
  );
};

export default Home;
